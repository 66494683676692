import { DateTime, Duration } from "luxon"

const DATETIME_FORMAT = "dd/MM/yyyy HH:mm";
export const stableNow = DateTime.now()

export function dtFromStr(dtStr: string): DateTime {
  return DateTime.fromFormat(dtStr, DATETIME_FORMAT, {zone: "Europe/Brussels"});
}

export function compareDtStrings(dtStrA: string, dtStrB: string): number {
  const dtA = dtFromStr(dtStrA)
  const dtB = dtFromStr(dtStrB)
  return dtA.toSeconds() - dtB.toSeconds();
}

export function isPastDate(dtStr: string) {
  const dt = dtFromStr(dtStr)
  return dt < stableNow;
}

export function isFarFutureDate(dtStr: string, farFuture: Duration = Duration.fromObject({months: 2})) {
  const dt = dtFromStr(dtStr);
  return dt > stableNow.plus(farFuture);
}

export function formatSalesLocationDatetime(start: string, end: string) {
  const startDt = dtFromStr(start)
  const endDt = dtFromStr(end)

  if (startDt.startOf("day").equals(endDt.startOf("day"))) {
    return `${startDt.toLocaleString(DateTime.DATE_HUGE, {locale: "nl-be"})} van ${startDt.toLocaleString(DateTime.TIME_24_SIMPLE, {locale: "nl-be"})} tot ${endDt.toLocaleString(DateTime.TIME_24_SIMPLE, {locale: "nl-be"})}`
  }
  else {
    return `${startDt.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY, {locale: "nl-be"})} tot ${endDt.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY, {locale: "nl-be"})}`
  }
}
