import * as React from "react"
import { useEffect, useMemo, useRef, useState } from "react"
import { Layout } from "../components/layout"
import { Button, Card, Col, Container, Row } from "react-bootstrap"
import { Seo } from "../components/seo"
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet"
import { graphql, Link } from "gatsby"
import { BoxArrowUpRight } from "react-bootstrap-icons"
import {
  compareDtStrings,
  dtFromStr,
  formatSalesLocationDatetime,
  isFarFutureDate,
  isPastDate,
  stableNow
} from "../utils/datetime"

function removeEndedSalesEvents(salesEvents) {
  return salesEvents.filter((salesLocation) => {
    return salesLocation.tempSalesLocation === "0" || !isPastDate(salesLocation.tempSalesEndDatetime)
  })
}

function removeFarFutureSalesEvents(salesEvents) {
  return salesEvents.filter((salesLocation) => {
    return salesLocation.tempSalesLocation === "0" || !isFarFutureDate(salesLocation.tempSalesEndDatetime)
  })
}

function groupSalesEventsByLocation(salesEvents) {
  const locationMap = new Map();
  for (const salesEvent of salesEvents) {
    const locationKey = `${salesEvent.locationLat}|${salesEvent.locationLon}`
    if (!locationMap.has(locationKey)) {
      locationMap.set(locationKey, [])
    }
    const locationGroup = locationMap.get(locationKey);
    locationGroup.push(salesEvent)
    locationMap.set(locationKey, locationGroup);
  }
  const locationGroups = Array.from(locationMap.values());
  return locationGroups.map(locationGroup => {
    return locationGroup.sort((salesEventA, salesEventB) => {
      if (!salesEventA.tempSalesStartDatetime && !salesEventB.tempSalesStartDatetime) {
        return 0
      }
      else if (!salesEventA.tempSalesStartDatetime) {
        return -1
      }
      else if (!salesEventB.tempSalesStartDatetime) {
        return -1
      }
      else {
        return compareDtStrings(salesEventA.tempSalesStartDatetime, salesEventB.tempSalesStartDatetime)
      }
    })
  })
}

function relevancySort(salesEventA, salesEventB) {
  // Our sort is performed first by start date of the event. However, for fixed
  // locations there is no start date, so we take an arbitrary date of +1 week
  // in the future. Internally then, they are sorted by name.
  const dtA = salesEventA.tempSalesStartDatetime ? dtFromStr(salesEventA.tempSalesStartDatetime) : stableNow.plus({weeks: 2});
  const dtB = salesEventB.tempSalesStartDatetime ? dtFromStr(salesEventB.tempSalesStartDatetime) : stableNow.plus({weeks: 2});

  const diff = dtA.toSeconds() - dtB.toSeconds();
  if (diff !== 0) {
    return diff;
  }
  else {
    return `${salesEventA.name}`.localeCompare(salesEventB.name);
  }
}

export default function Verkooppunten({data}) {
  const [map, setMap] = useState(null);
  const mapRef = useRef(null);
  const [salesLocationData, setSalesLocationData] = useState([]);

  useEffect(() => {
    setSalesLocationData(data.allVerkooppuntenCsv.nodes)
  }, [data])

  let salesEvents = removeEndedSalesEvents(salesLocationData);
  salesEvents = removeFarFutureSalesEvents(salesEvents);
  const salesLocations = groupSalesEventsByLocation(salesEvents);
  salesEvents = salesLocations.map(salesLocation => salesLocation[0]);
  salesEvents = salesEvents.sort(relevancySort);

  const displayMap = useMemo(
    () => {
      return !salesLocationData ? undefined : (
        <MapContainer ref={setMap} style={{ height: '400px' }} center={[50.95481765638107, 5.3249560412309345]} zoom={10} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[50.95481765638107, 5.3249560412309345]}>
            <Popup>
              <h5>
                Zelfgemaakt
              </h5>
              <p className="fw-bold">op afspraak</p>
              <p>Ons huiswinkeltje. Bezoek mogelijk op afspraak.</p>
              <Link to="/contact/">Afspraak maken</Link>
            </Popup>
          </Marker>
          {
            salesEvents.map((salesLocation) => {
              return (
                <Marker key={salesLocation.id} position={[salesLocation.locationLat, salesLocation.locationLon]}>
                  <Popup>
                    <h5>
                      {salesLocation.name}
                    </h5>
                    {
                      salesLocation.tempSalesLocation === "1" && (
                        <p className="fw-bold">
                          {formatSalesLocationDatetime(salesLocation.tempSalesStartDatetime, salesLocation.tempSalesEndDatetime)}
                        </p>
                      )
                    }
                    <p>{salesLocation.descriptionShort}</p>
                    <p>
                      <a href={salesLocation.website} target="_blank" rel="noopener">Meer informatie <BoxArrowUpRight className="small" /></a>
                    </p>
                  </Popup>
                </Marker>
              )
            }
          )
        }
      </MapContainer>
    )},
    [salesLocationData]
  )

  const showOnMap = (location) => {
    mapRef && mapRef.current && mapRef.current.scrollIntoView();
    setTimeout(() => {
      map && map.flyTo(location, 13);
    }, 500)
  }

  return (
    <Layout>
      <Container className="pt-7 pb-4">
        <h1 className="display-2 fw-bold text-center mb-5">
          Onze verkooppunten
        </h1>
        <Row className="mb-6">
          <Col xl={6} className="pt-sm-4">
            <p>
              Wil je graag zelfgemaakt lekkers in huis halen? Bestel dan meteen via de webshop.
            </p>
            <Link to={"/producten/"} className="btn btn-primary text-light mb-5">Naar de webshop</Link>
            <p>
              Als je ons graag fysiek
              een bezoekje brengt, kan dat natuurlijk ook in <a className="fw-bold text-primary" href="#scrollmap" onClick={(e) => {e.preventDefault(); showOnMap([50.95481765638107, 5.3249560412309345]);}}>ons huiswinkeltje</a> (<Link className="text-decoration-underline" to="/contact/">op afspraak</Link>).
            </p>
            <p>
              Onze producten –&nbsp;of een deel van het gamma&nbsp;– vind je alvast bij de volgende verkooppunten.
            </p>
          </Col>
          <Col xl={6} ref={mapRef} className="pt-4">
            {displayMap}
          </Col>
        </Row>
        <Row>
          {
            salesEvents.map((salesLocation) => {
              return (
                <Col xxl={3} lg={4} sm={6} className="mb-4 d-flex align-items-stretch" key={salesLocation.id}>
                  <Card key={salesLocation.id} className="flex-grow-1 d-flex">
                    <Card.Body className="flex-grow-1 d-flex flex-column justify-content-between">
                      <div>
                        <Card.Title as="h2" className="text-center">{salesLocation.name}</Card.Title>
                        {
                          salesLocation.tempSalesLocation === "1" && (
                            <p className="fw-bold text-muted">
                              {formatSalesLocationDatetime(salesLocation.tempSalesStartDatetime, salesLocation.tempSalesEndDatetime)}
                            </p>
                          )
                        }
                        <p>{salesLocation.descriptionShort}</p>
                        <p>{salesLocation.descriptionLong}</p>
                      </div>
                      <div className="d-flex flex-column align-items-stretch">
                        {
                          salesLocation.website && (
                            <a href={salesLocation.website} className="btn btn-primary text-light mb-3" target="_blank" rel="noopener">Website bezoeken <BoxArrowUpRight className="ms-1 mb-1 small" /></a>
                          )
                        }
                        <Button variant="outline-secondary" onClick={() => {
                          showOnMap([salesLocation.locationLat, salesLocation.locationLon])
                        }}>Toon op kaart</Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              )
            })
          }
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allVerkooppuntenCsv {
      nodes {
        id
        address
        descriptionShort
        descriptionLong
        name
        website
        locationLat
        locationLon
        tempSalesDatetimeHuman
        tempSalesEndDatetime
        tempSalesLocation
        tempSalesStartDatetime
      }
    }
  }
`

export const Head = () => (
  <Seo
    title="Onze verkooppunten"
    description="Kun je niet wachten om je tanden in ons zelfgemaakt lekkers te zetten? Groot gelijk! Rep je meteen naar een van onze verkooppunten."
  />
)
